<script lang="ts" setup>
import Title from './title.vue'
import { getMenusApi } from '@/api/menu'

const menus = ref<Tree<MenuNode>[]>()
const router = useRouter()

onMounted(getMenu)
async function getMenu() {
  const res = await getMenusApi()
  menus.value = res.slice(0, 7)
}

const lineWidth = ['w-197px', 'w-125px', 'w-118px', 'w-105px', 'w-113px', 'w-88px', 'w-75px']

function onNavTo(node: MenuNode) {
  router.push({ path: '/safe', query: { id: node.id } })
}
</script>

<template>
  <Title title="楼层展示" />
  <div class="floor-map">
    <div class="pic" />
    <div class="text">
      <div v-for="(menu, index) in menus" :key="menu.id" class="line-content">
        <div class="line" :class="[lineWidth[index]]" />
        <div class="line-text" @click="onNavTo(menu.node)">
          {{ menu.node.menuName }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.floor-map {
    position: relative;

    .pic {
        width: 357px;
        height: 357px;
        background: url('@/assets/images/big-screen2/floor-pic.png') center / contain no-repeat;
    }

    .text {
        width: 200px;
        height: 220px;
        position: absolute;
        top: 35px;
        right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .line-content {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .line {
                margin-right: 7px;
                border-bottom: 1px dashed #BFEAFB;
            }

            .line-text {
                font-size: 12px;
                color: #BFEAFB;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }

}
</style>
