<script setup lang="ts">
import { formatDate } from '@vueuse/core'
import Title from './title.vue'
import DealAlarm from './deal-alarm.vue'
import { queryPlatformRuntimeEventsApi } from '@/api/event'
import { closeSocket, openSocket } from '@/utils/websocket/WebsocketAction'
import emitter from '@/utils/mitt'

const warnList = ref<HistoryPlatformEvent[]>([])
const videoPlayVisible = ref(false)
const liveVisible = ref(false)
const videoPath = ref('')
const deviceName = ref('')
const deviceCode = ref('')

onMounted(openSocket)
onBeforeUnmount(closeSocket)
onMounted(() => {
  emitter.on('onPushPlatformEventTrigger', onEventTriggerHandler)

  emitter.on('onPushPlatformEventDisappear', onEventDisappear)
})

function onEventTriggerHandler(e: any) {
  // onShowLive(e.event)
  warnList.value.push(e.event)
}

function onEventDisappear(e: any) {
  const index = warnList.value.findIndex(item => item.id === e.event.id)
  if (index !== -1) {
    warnList.value.splice(index, 1)
  }
}

onBeforeUnmount(() => {
  emitter.off('onPushPlatformEventTrigger', onEventTriggerHandler)
  emitter.off('onPushPlatformEventDisappear', onEventDisappear)
})

onMounted(queryPlatformRuntimeEvents)

async function queryPlatformRuntimeEvents() {
  const { list } = await queryPlatformRuntimeEventsApi({ confirmed: false, eventTypes: ['WARN'] } as RuntimeEventParam)
  warnList.value = list
}

function onPlayVideo(event: HistoryPlatformEvent) {
  deviceName.value = event.deviceName
  videoPath.value = event.videoPath
  videoPlayVisible.value = true
}

function onShowLive(event: HistoryPlatformEvent) {
  liveVisible.value = true
  deviceCode.value = event.videoDeviceCode
  deviceName.value = event.deviceName
}

function onCloseVideo() {
  videoPlayVisible.value = false
  videoPath.value = ''
  deviceName.value = ''
}

function onLiveClose() {
  liveVisible.value = false
  deviceName.value = ''
  deviceCode.value = ''
}

const dealVisible = ref(false)
const toDealAlarm = ref<HistoryPlatformEvent>()
function onDeal(event: HistoryPlatformEvent) {
  toDealAlarm.value = event
  dealVisible.value = true
}

function onCloseDeal() {
  dealVisible.value = false
}

async function onDealed() {
  await queryPlatformRuntimeEvents()
  onCloseDeal()
}
</script>

<template>
  <Title title="实时告警" />
  <div h-full overflow-y-scroll class="scroll-container">
    <table>
      <thead>
        <tr class="head">
          <th scope="col">
            设备名称
          </th>
          <th scope="col">
            设备编号
          </th>
          <th scope="col">
            触发时间
          </th>
          <th scope="col" w-100px>
            操作
          </th>
        </tr>
      </thead>
      <tbody class="body">
        <tr v-for="event in warnList" :key="event.id">
          <td scope="row">
            {{ event.deviceName }}
          </td>
          <td>{{ event.deviceCode }}</td>
          <td>{{ formatDate(new Date(event.triggeredAt), 'YYYY-MM-DD HH:mm') }}</td>
          <td class="operation">
            <div v-if="event.videoPath" class="record item" cursor-pointer @click="onPlayVideo(event)">
              录像
            </div>
            <div v-if="event.videoDeviceCode" class="deal item" cursor-pointer @click="onShowLive(event)">
              直播
            </div>
            <div v-if="!event.confirmType" class="deal item" cursor-pointer @click="onDeal(event)">
              处理
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <HistoryVideo :device-name="deviceName" video-player-status="EASY_PLAYER" :video-path="videoPath" :visible="videoPlayVisible" @on-close="onCloseVideo" />
  <LiveVideo :device-name="deviceName" :device-code="deviceCode" :visible="liveVisible" @on-close="onLiveClose" />
  <DealAlarm :id="toDealAlarm?.id" :visible="dealVisible" @cancel="onCloseDeal" @confirm="onDealed" />
</template>

<style lang="scss" scoped>
.scroll-container::-webkit-scrollbar {
  display: none; /* 针对Webkit浏览器 */
}
/* 设置表格的边框样式 */
  table {
    margin-top: 10px;
    border-collapse: collapse;
    /* 边框合并 */
    width: 100%;
    /* 表格宽度 */
    color: #fff;

    .body{
        height: 100%;
    }
  }

  /* 设置表格单元格的边框样式 */
  table,
  th,
  td {
    height: 42px;
    border: 1px solid #15C3E9;
    /* 单元格边框为1px实线黑色 */
  }

  /* 设置表头样式 */
  th {
    background-color: rgba(21, 195, 233, 0.2);
    /* 表头背景色 */
    padding: 8px;
    /* 内边距 */
    text-align: left;
    /* 文本对齐方式 */
  }

  /* 设置表格单元格样式 */
  td {
    text-align: center;
    height: 42px;
    /* 内边距 */
    font-size: 12px;
  }

  .operation {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    .item {
      margin-right: 3px;
      color: #15C3E9;
      &:last-child{
        margin-right: 0;
      }
      // border-radius: 4px;
      // padding: 5px 8px;
    }

    .record {
      // margin-right: 4px;
      // background-color: rgba(248, 166, 43, 1);
    }

    .deal {
      // background: rgba(43, 137, 248, 1);
    }
  }

  .head td {
    font-size: 14px;
    background: rgba(21, 195, 233, 0.2);
  }

  .footer {
    display: flex;
    border: 1px solid #15C3E9;
    margin-top: -1px;
    color: #fff;

    div {
      flex: 1;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background: rgba(21, 195, 233, 0.2);
      border-right: 1px solid #15C3E9;

      &:last-child {
        border-right: none;
      }
    }
  }
</style>
