<script lang="ts" setup>
import Title from './title.vue'
import type { ECOption } from '@/components/echarts/config'

const props = defineProps({
  sub: {
    type: Object as PropType<SubSys>,
    default: () => ({}),
  },
})

const pieChart = ref()

onMounted(() => {
  initEcharts()
})

const option = ref<ECOption>({})

async function initEcharts() {
  const data = [
    { name: '在线', value: props.sub.online },
    { name: '离线', value: props.sub.offline },
  ]

  option.value = {
    title: [
      {
        text: '设备总数',
        left: '29%',
        top: '55%',
        textAlign: 'center',
        textStyle: {
          fontSize: 16,
          fontWeight: 400,
          color: '#BFEAFB',
        },
      },
      {
        text: `${props.sub.online + props.sub.offline}`,
        left: '29%',
        top: '30%',
        textAlign: 'center',
        textStyle: {
          fontSize: 40,
          fontWeight: 700,
          color: '#BFEAFB',
        },
      },
    ],
    legend: {
      show: true,
      top: 'middle',
      right: '80px',
      icon: 'circle',
      orient: 'vertical',
      align: 'auto',
      itemWidth: 8,
      textStyle: {
        color: '#BFEAFB',
      },
      data: [
        {
          name: '在线',
          icon: 'circle',
          itemStyle: {
            color: 'rgba(59, 255, 150, 1)',
          },
        },
        {
          name: '离线',
          icon: 'circle',
          itemStyle: {
            color: 'rgba(87, 107, 129, 1)',
          },
        },
      ],
      itemGap: 20,
      formatter(name: string) {
        let text = ''
        data.forEach((val) => {
          if (val.name === name) {
            text = `${name} ${val.value}`
          }
        })

        return text
      },
      // data: data.map((val: ChartProp) => val.name)
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b} :  {c}',
    },
    series: [
      {
        type: 'pie',
        radius: ['60%', '80%'],
        center: ['30%', '50%'],
        color: ['#3BDCFF', '#BFEAFB', '#15E9C3', '#15C3E9', '#00FFFF', '#4AEAB0'],
        itemStyle: {},
        data,
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
      },
    ],
  }
}
</script>

<template>
  <Title :title="sub.name ?? '未知系统'" />
  <div id="model-device-statistic" flex-1>
    <echarts ref="pieChart" :option="option" />
  </div>
</template>

<style lang="scss" scoped>
#model-device-statistic {
    width: 100%;
    height: 192px;
    // color: #09e388;
}
</style>
