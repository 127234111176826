<script lang="ts" setup>
import TopHead from './big-screen/top-head.vue'
import Middle from './big-screen/middle.vue'
import Left from './big-screen/left.vue'
import Right from './big-screen/right.vue'
import { getDeviceOnlineBySubsysStatisticApi } from '@/api/statistic'

const countDeviceOnlineBySubSys = ref<CountDeviceOnlineBySubSys>()

onMounted(getDeviceOnlineBySubsysStatistic)

async function getDeviceOnlineBySubsysStatistic() {
  countDeviceOnlineBySubSys.value = await getDeviceOnlineBySubsysStatisticApi()
}
</script>

<template>
  <page-container>
    <div id="out-dom" w-full h-full overflow-hidden>
      <screen-adapter width="1920" height="1080" out-dom-id="out-dom">
        <div class="screen-container">
          <TopHead />

          <div class="center-bg">
            <div class="center-content">
              <Left />
              <Middle :online="countDeviceOnlineBySubSys?.online ?? 0" :offline="countDeviceOnlineBySubSys?.offline ?? 0" />
              <Right :sub-sys="countDeviceOnlineBySubSys?.subSys ?? []" />
            </div>
          </div>
        </div>
      </screen-adapter>
    </div>
  </page-container>
</template>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.screen-container {
  width: 100%;
  height: 100%;
  background-color: #011D38;

  .center-bg {
    width: 1896px;
    height: 976px;
    background-image: url("@/assets/images/big-screen2/center-bg.svg");
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // padding: 12px;
    margin: 12px;

    .center-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px;
    }
  }
}
</style>
