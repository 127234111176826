<script setup lang="ts">
import { onMounted, onUnmounted, reactive } from 'vue'

const props = withDefaults(defineProps<{ height?: string, width?: string, outDomId?: string }>(), {
  height: `${document.documentElement.clientHeight}`,
  width: `${document.documentElement.clientWidth}`,
})

const style = reactive({
  width: `${props.width}px`,
  height: `${props.height}px`,
  transform: 'scale(1) translate(-50%, -50%)',
})

const outDom = ref<any>()
const resizeObserver = ref<ResizeObserver | null>(null)

const debounceSetScale = setScale

// 获取放大缩小比例
function getScale() {
  const outerWidth = outDom.value ? outDom.value.clientWidth : window.innerWidth
  const outerHeight = outDom.value ? outDom.value.clientHeight : window.innerHeight
  const w = outerWidth / Number(props.width)
  const h = outerHeight / Number(props.height)

  return w < h ? w : h
}

// 设置比例
function setScale() {
  style.transform = `scale(${getScale()}) translate(-50%, -50%)`
}

onMounted(() => {
  if (props.outDomId)
    outDom.value = document.getElementById(props.outDomId as string)
  setScale()
  window.addEventListener('pageshow', setScale)
  window.addEventListener('resize', debounceSetScale)

  if (outDom.value) {
    resizeObserver.value = new ResizeObserver(() => {
      debounceSetScale()
    })
    resizeObserver.value.observe(outDom.value)
  }
})

onUnmounted(() => {
  window.removeEventListener('pageshow', setScale)
  window.removeEventListener('resize', debounceSetScale)

  if (resizeObserver.value && outDom.value)
    resizeObserver.value.unobserve(outDom.value)
})
</script>

<template>
  <div class="ScreenAdapter" :style="style">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.ScreenAdapter {
	transform-origin: 0 0;
	position: absolute;
	left: 50%;
	top: 50%;
	transition: 0.3s;
}
</style>
