<script lang="ts" setup>
import { Empty } from 'ant-design-vue'
import Title from './title.vue'
import { getCachesSystemPublicConfigApi } from '@/api/system'
import { getTvWallApi } from '@/api/tv-wall'

const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE

const timer = ref<number | null>(null)
const publicConfig = ref<PublicConfig>()
const wallVideos = ref<Record<number, TvVideo[]>>()
const containerMax = ref(1)

onMounted(async () => {
  publicConfig.value = await getCachesSystemPublicConfigApi()
  if (publicConfig.value.homeWallId) {
    const { videos, containerMax: max } = await getTvWallApi(publicConfig.value.homeWallId)

    const videoMap = {} as any

    if (videos && videos.length > 0) {
      videos.forEach((video) => {
        if (videoMap[video.containerIndex]) {
          videoMap[video.containerIndex].push(video)
        }
        else {
          videoMap[video.containerIndex] = [video]
        }
      })
    }

    containerMax.value = max
    wallVideos.value = videoMap
  }
})

onBeforeUnmount(() => {
  clearInterval(timer.value as number)
  timer.value = null
})
</script>

<template>
  <div class="video-parts" flex flex-col>
    <Title title="重点区域视频" />
    <div class="divider" />
    <div flex-1 flex>
      <GridScreen :container-max="containerMax" pos-relative>
        <template #default="{ index }">
          <div v-if="wallVideos && wallVideos[index] && wallVideos[index].length > 0" w-full h-full>
            <CustomLoopVideo :videos="wallVideos[index]" />
          </div>
          <div v-else h-full w-full flex justify-center items-center>
            <a-empty :image="simpleImage" description="无视频" />
          </div>
        </template>
      </GridScreen>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.player-container) {
  .player-item {
    padding: 2px;
    .player-item-inner {
      background-color: transparent;
      padding: 0;
    }
  }
}

.video-parts {
  height: 794px;
  padding: 20px 24px;
  background: url('@/assets/images/big-screen2/middle-bg.svg') center / contain no-repeat;

  .divider {
    height: 14px;
  }
}
</style>
