<script lang="ts" setup>
import { queryDevicesApi } from '@/api/device'
import { queryPlatformRuntimeEventsApi } from '@/api/event'

defineProps({
  online: { type: Number, default: () => 0 },
  offline: { type: Number, default: () => 0 },
})

const deviceAmount = ref(0)
const alarmAmount = ref(0)

onMounted(queryDevices)

async function queryDevices() {
  const res = await queryDevicesApi({ current: 1, size: 20 })
  deviceAmount.value = res.total
}

onMounted(queryRuntimeEvents)

async function queryRuntimeEvents() {
  const res = await queryPlatformRuntimeEventsApi({ confirmed: false, eventTypes: ['WARN'] } as RuntimeEventParam)
  alarmAmount.value = res.list.length
}
</script>

<template>
  <div class="amount">
    <div class="amount-left part">
      <div class="num num1">
        {{ online }}
      </div>
      <div class="text">
        在线设备数
      </div>
    </div>
    <div class="amount-middle part">
      <div class="num num2">
        {{ offline }}
      </div>
      <div class="text">
        离线设备数
      </div>
    </div>
    <div class="amount-right part">
      <div class="num num3">
        {{ alarmAmount }}
      </div>
      <div class="text">
        报警数
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.amount {
    display: flex;
    justify-content: center;
    margin-bottom: 22px;
    height: 136px;
    font-size: 21px;
    color: #BFEAFB;
    text-align: center;

    .num {
        font-size: 72px;
        font-weight: 700;
        margin-bottom: -16px;
    }

    .num1 {
        color: #3CFF96;

    }

    .num2 {
        color: #BFEAFB;
    }

    .num3 {
        color: #FF7043;
    }

    .part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .amount-left {
        width: 210px;
        height: 100%;
        background: url('@/assets/images/big-screen2/amount-left.svg') center / contain no-repeat;
    }

    .amount-middle {
        width: 210px;
        height: 100%;
        margin: 0 30px;
        background: url('@/assets/images/big-screen2/amount-middle.svg') center / contain no-repeat;
    }

    .amount-right {
        width: 210px;
        height: 100%;
        background: url('@/assets/images/big-screen2/amount-right.svg') center / contain no-repeat;
    }
}
</style>
