<script lang="ts" setup>
import { ExpandOutlined, SettingFilled } from '@ant-design/icons-vue'
import { onFullScreen } from '@/utils/screen'

const appStore = useAppStore()
const { layoutSetting } = storeToRefs(appStore)

function goOldPlatform() {
  const { protocol, hostname } = window.location
  // 老平台8080
  const url = `${protocol}//${hostname}:8080`
  window.open(url, '_blank')
}
</script>

<template>
  <div class="header">
    <div class="left aside">
      <div class="text">
        总建筑面积：2800平米 总楼层数：地上5层地下1层
      </div>
    </div>
    <div class="middle-title">
      {{ layoutSetting.title }}
    </div>
    <div class="right aside">
      <div flex justify-end items-center pr-58px>
        <ExpandOutlined style="font-size: 20px;cursor: pointer;" mr-10px @click="onFullScreen('#out-dom')" />
        <div px-10px py-3px flex justify-center items-center class="settings" @click="goOldPlatform">
          <SettingFilled style="margin-right: 7px;" />
          去配置
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  background: url("@/assets/images/big-screen2/top-head.svg") center no-repeat;
  background-size: 100% 100%;

  .aside {
    flex: 1;
    display: flex;
    height: 100%;
    position: relative;
    font-size: 14px;
    font-weight: 700;
    color: #BFEAFB;

    .text {
      position: absolute;
      top: 14px;
    }
  }

  .left {
    .text {
      left: 55px;
      cursor: pointer;
    }
  }

  .middle-title {
    width: 1100px;
    font-size: 32px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg, #CBD9F4 0%, #B8F3FE 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .right {
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    .settings{
      cursor: pointer;
      border:1px solid rgba(21, 195, 233, 1);
      color: rgba(21, 195, 233, 1);
    }

    .text {
      display: flex;
      right: 55px;

      .entry {
        color: #3BDCFF;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}
</style>
